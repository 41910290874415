<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                Manual enrolment for practical exams
                                </h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\ Manual enrolment
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" style="min-height: 100vh;">
                        <v-row>
                            <v-col cols="12">
                                <label>Manual enrolment on behalf of: </label>
                            </v-col>
                            <v-col cols="12" md="12">
                              <v-autocomplete dense outlined :search-input.sync="accountHolderSearch" v-model="accountHolderId" :filter="() => true"
                                              :items="accountHolders" clearable item-text="custom_name_with_email" item-value="id" :error="$v.accountHolderId.$error">
                                <template v-slot:no-data>
                                  <div class="p-3 font-size-sm">Enter the enroller name or email </div>
                                </template>
                                <template v-slot:label>
                                  Select enroller <span class="text-danger">*</span>
                                </template>
                              </v-autocomplete>
                              <span class="text-danger" v-if="$v.accountHolderId.$error">This information is required</span>

                            </v-col>
                            <v-col cols="12" md="12" class="text-right">
                              <v-btn @click="getExamInformation" x-large dark>
                                Continue
                              </v-btn>
                            </v-col>

                        </v-row>
                    </div>
                </div>
            </div>
        </div>
    </v-app>    
</template>

<script>
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import NotFound from "@/view/pages/view/components/NotFound";
import {email, required, requiredIf} from "vuelidate/lib/validators";

const accountHolder=new AccountHolderService();
export default {
  components: {
    NotFound,
  },
  validations: {
    accountHolderId:{required}
  },
  data(){
    return{
      accountHolders:[],
      accountHolderSearch:'',
      accountHolderId:null,
    }
  },
  methods:{
    getExamInformation(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.$router.push({
          name: 'manual-enrolment-step-2',
          params: {accountHolderId: this.accountHolderId}
        })
      }
    },
    async searchAccountHolder(data)
    {
      accountHolder
          .search(data)
          .then((response) => {
            this.accountHolders = response.data.accountHolders;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    }
  },
  watch: {
    accountHolderSearch(val) {

      let data = {
        info:val,
      };
      this.searchAccountHolder(data);
    },
  }

}
</script>
